<div class="page-container">
  <div class="row">
    <div class="col-md-6 image-container">
      <img src="assets/images/propic.jpeg" class="avatar-image spring"  alt="profile picture"/>
    </div>
    <div class="col-md-6 image-container">
      <img src="assets/images/logo-white.svg" class="avatar-image home-logo spring" alt="logo" />
    </div>
  </div>

  <h1 class="footer_title"><small> &#60; Developer turning coffee into code. /&#62; </small></h1>

  <pre>
    using System;
    using FullStackDeveloper;

    public static class Rohith extends FullStackDeveloper &#123;
      public Rohith() &#123;
      	while (developer.required === true) &#123;
          Console.WriteLine("I am more than happy to do it. Feel free to contact me.");
          break;
        &#10101;
      &#10101;
      
      public intro (me) &#123;
        me.name = "Rohith";
        me.location = "Melbourne, Australia";
        me.isFullStack = true;
      &#10101;
    &#10101;
  </pre>
</div>
