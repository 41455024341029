
<div class="page-container">
  <div class="row">
    <div class="col-md-6">
      <app-heading heading="My social presence"></app-heading>

      <div class="row">
        <div *ngFor="let item of social" class="col-md-6 card-container">
          <app-card [title]="item.name" [image]="item.image" [href]="item.url" />
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <app-heading heading="Contact me"></app-heading>

      <div class="row">
        <form class="contact-form" [formGroup]="contactForm" #submitForm="ngForm" novalidate (ngSubmit)="sendContact(contactForm.value)">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input type="text" matInput formControlName="name" id="name" placeholder="John Smith">
            <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
            <mat-error *ngIf="hasError('name', 'maxlength')">You have more than 60 characters</mat-error>
          </mat-form-field>
  
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input type="email" matInput formControlName="email" id="email" placeholder="Ex. pat@example.com">
            <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
            <mat-error *ngIf="hasError('email', 'email')">Please enter a valid email address</mat-error>
          </mat-form-field>
  
          <mat-form-field appearance="outline">
            <mat-label>Subject</mat-label>
            <input matInput formControlName="subject" id="subject" placeholder="Subject">
            <mat-error *ngIf="hasError('subject', 'required')">Subject is required</mat-error>
            <mat-error *ngIf="hasError('subject', 'maxlength')">You have more than 1000 characters</mat-error>
          </mat-form-field>
  
          <mat-form-field appearance="outline">
            <mat-label>Let us know your voice.</mat-label>
            <textarea matInput formControlName="message" id="message" placeholder="Let us know your voice"></textarea>
            <mat-error *ngIf="hasError('message', 'required')">Message is required</mat-error>
            <mat-error *ngIf="hasError('message', 'maxlength')">You have more than 1000 characters</mat-error>
          </mat-form-field>
  
          <div class="button-container">
            <button mat-raised-button color="warn" (click)="reset()">Clear</button>
            <button mat-raised-button color="primary" [disabled]="!contactForm.valid">Send</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
