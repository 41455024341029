<mat-toolbar class="nav-bar">
  <div class="nav-left-container">
    <div class="nav-left-containers">
      <button mat-button [mat-menu-trigger-for]="menu" fxShow fxHide.gt-sm>
        <mat-icon>menu</mat-icon>
      </button>
      <a routerLink="/"><img src="assets/images/propic.jpeg" class="pic"  alt="profile picture"></a>
      <div class="header-message">
        <a routerLink="/" class="app-name">Rohith Poyyeri</a>
      </div>
    </div>
  </div>

	<span class="fill-remaining-space"></span>

	<div fxLayout="row" fxHide fxShow.gt-sm>
    <a mat-button class="menu-button" *ngFor="let item of menuItems" routerLink="{{item.link}}">
      <i class="{{item.icon}}"></i> {{item.name}}
    </a>
	</div>
</mat-toolbar>

<mat-menu x-position="before" #menu>
  <a mat-menu-item class="menu-item" *ngFor="let item of menuItems" routerLink="{{item.link}}">
    <i class="{{item.icon}}"></i> {{item.name}}
  </a>
</mat-menu>
