import { InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken('app.config');

export const AppConfig: any = {
  routes: {
    about: 'about',
    contact: 'contact',
    expertise: 'expertise',
    experience: 'experience',
    projects: 'projects',
    error404: '404',
  },
  endpoints: {

  },
  repositoryURL: 'https://bitbucket.org/rohithart/portfolio'
};
