import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../../configs/app.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  appConfig: any;
  menuItems?: any[];

  constructor(@Inject(APP_CONFIG) appConfig: any) {
    this.appConfig = appConfig;
  }

  ngOnInit() {
    this.loadMenus();
  }

  private loadMenus(): void {
    this.menuItems = [
      { link: '/', name: 'Home', icon: 'fas fa-home' },
      { link: '/' + AppConfig.routes.expertise, name: 'Expertise', icon: 'fas fa-shield-alt' },
      { link: '/' + AppConfig.routes.experience, name: 'Experience', icon: 'fas fa-building' },
      { link: '/' + AppConfig.routes.projects, name: 'Projects', icon: 'fas fa-flask' },
      { link: '/' + AppConfig.routes.about, name: 'About', icon: 'fas fa-user' },
      { link: '/' + AppConfig.routes.contact, name: 'Contact', icon: 'fas fa-phone' },
      { link: '/' + AppConfig.routes.error404, name: '404', icon: 'fas fa-jedi' },
    ];
  }
}
