import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './modules/material.module';
import { AboutComponent } from '../modules/about/about.component';
import { ContactComponent } from '../modules/contact/contact.component';
import { ExpertiseComponent } from '../modules/expertise/expertise.component';
import { ExperienceComponent } from '../modules/experience/experience.component';

import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HeadingComponent } from './components/heading/heading.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { Error404PageComponent } from './pages/error404-page/error404-page.component';
import { ProjectComponent } from '../modules/projects/projects.component';
import { CardComponent } from './components/card/card.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    HeadingComponent,
    CardComponent,
    HomePageComponent,
    Error404PageComponent,
    AboutComponent,
    ContactComponent,
    ExpertiseComponent,
    ExperienceComponent,
    ProjectComponent,
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    HeaderComponent,
    FooterComponent,
    MaterialModule,
  ]
})

export class SharedModule {
}
