import { Component } from '@angular/core';
import { BrowserService } from 'src/app/shared/services/browser.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})

export class AboutComponent {
  constructor(private browserService: BrowserService) {
    this.browserService.init('About me', 'Rohith Poyyeri is an application developer from Melbourne, please feel free to get in touch', '');
  }
}
