<app-heading heading="Page not found"></app-heading>

<div class="page-container">
  <div class="row">
    <div class="col-md-6 image-container">
      <div class="punch">
        May the force be with you
      </div>
    </div>
    <div class="col-md-6 image-container">
      <img src="assets/images/404.gif"  alt="not found">
    </div>
  </div>
</div>
