<div class="page-container">
  <div class="row">
    <div class="col-md-6">
      <app-heading heading="About me"></app-heading>

      <div class="image-container">
        <img src="assets/images/propic.jpeg" class="avatar-image" alt="profile picture"/>
      </div>

      <p class="details">
        Graduated from Monash University, Melbourne, Australia (Masters in IT Professional) with many years of experience in the IT industry with major multi national software conglomerates. Possess a plethora of skills and has experience in wide variety of platform
        and development environment. Looking forward to grow in leadership and knowledge, excel in innovative technology application, interact and share with team members and colleagues and develop world class solutions to real world challenges.
      </p>

    </div>
    <div class="col-md-6">
      <app-heading heading="Qualifications"></app-heading>


      <p class="details">Masters in Information and Technology Professional</p>
      <p class="details">Bachelor of Technology in Electrical and Electronics Engineering</p>

      <app-heading heading="Interesting facts"></app-heading>

      <p class="details">HUGE StarWars fan</p>
      <p class="details">Drummer</p>
      <p class="details">Electrical Engineer by degree</p>
      <p class="details">Travel and Blog</p>


      <div class="details punch">
        I can speak English, Hindi, Malayalam, Tamil and more than 20 other languages that computers can speak.
      </div>
    </div>
  </div>
</div>
