import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  constructor(@Inject(DOCUMENT) private dom: any, private titleService: Title, private metaService: Meta){
  }

  init(title: string, description: string, keywords: string) {
    this.setTitle(title);
    this.setMeta(description, keywords);
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} - rohithpoyyeri.com`);
  }

  setCanonicalURL() {
    const canonicalTag = this.metaService.getTag('rel="canonical"');
    if (canonicalTag) {
      this.metaService.updateTag({ rel: 'canonical', href: this.dom.URL });
    } else {
      this.metaService.addTag({ rel: 'canonical', href: this.dom.URL });
    }
  }

  setMeta(description: string, keywords: string ) {
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'keywords', content: keywords });
    this.setCanonicalURL();
    this.addDefaultTags();
  }

  private addDefaultTags() {
    this.metaService.addTag({ name: 'robots', content: 'index,follow' });
    this.metaService.addTag({ property: 'og:title', content: 'Rohith Poyyeri.' });
  }
}
