import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Contact } from 'src/app/shared/models/Contact';
import { BrowserService } from 'src/app/shared/services/browser.service';
import { EmailService } from 'src/app/shared/services/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})

export class ContactComponent {
  @ViewChild("submitForm") private submitForm!: NgForm;

  public contactForm: FormGroup;

  constructor(private emailService: EmailService, private toaster:ToastrService, private browserService: BrowserService) {
    this.browserService.init('Rohith Poyyeri', 'Rohith Poyyeri is an application developer from Melbourne, please feel free to get in touch', '');

	  this.	contactForm = new FormGroup({
	    name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
	    email: new FormControl('', [Validators.required, Validators.email]),
	    subject: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
	    message: new FormControl('', [Validators.required, Validators.maxLength(1000)])
	  });
  }

  social = [
    { name: 'LinkedIn', url: 'https://www.linkedin.com/in/rohithpoyyeri/', image: 'assets/images/social/linkedin.png' },
    { name: 'Github', url: 'https://github.com/RohithPoyyeri', image: 'assets/images/social/github.png' },
    { name: 'Facebook', url: 'https://www.facebook.com/rohith.r.p/', image: 'assets/images/social/fb.png' },
    { name: 'Instagram', url: 'https://www.instagram.com/rohithart/', image: 'assets/images/social/insta.png' },
    { name: 'Twitter', url: 'https://twitter.com/rohithart/', image: 'assets/images/social/twitter.png' },
    { name: 'Quora', url: 'https://www.quora.com/profile/Rohith-Poyyeri/', image: 'assets/images/social/quora.png' },
  ]

  hasError = (controlName: string, errorName: string) =>{
	  return this.contactForm.controls[controlName].hasError(errorName);
  }

	public sendContact = (contactValue: Contact) => {
	  if (this.contactForm.valid) {
	    this.sendEmail(this.contactForm.value);
	  }
	}

	public reset() {
	  this.contactForm.reset({
	    'name': '',
	    'email': '',
	    'subject': '',
	    'message': '',
	  });
	}

	private sendEmail = (formData: any) => {
	  this.emailService.sendContactEmail(formData).then(
	    (data) => {
	      this.submitForm.resetForm();
	      this.toaster.success('Success', 'Email successfully send.');
	    },
	    (error) => {
	      console.log(error);
	    }
	  );
	}
}
