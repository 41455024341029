<footer>
    <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" [class.xs]="'footer-xs'">
        <div fxFlex="33">
            <span> rohithpoyyeri.com {{date}}</span>
        </div>
        <div fxFlex="33" class="text--center footer-text">
            <span>
        Made with <i class="fas fa-heart"></i> by
        Rohith Poyyeri
      </span>
        </div>
        <div fxFlex class="text--right" [class.xs]="'footer-xs'">
            <a href="https://www.facebook.com/rohith.r.p" aria-label="Facebook" target="_blank" rel="nofollow noopener noreferrer" class="footerBanner">
                <i class="fab fa-facebook-square"></i>
            </a>
            <a href="https://www.instagram.com/rohithart/" aria-label="Instagram" target="_blank" rel="nofollow noopener noreferrer" class="footerBanner">
                <i class="fab fa-instagram"></i>
            </a>
            <a href="https://twitter.com/rohithart" target="_blank" aria-label="Twitter" rel="nofollow noopener noreferrer" class="footerBanner">

                <i class="fab fa-twitter-square"></i>
            </a>
            <a href="https://www.linkedin.com/in/rohithpoyyeri/" aria-label="LinkedIn" target="_blank" rel="nofollow noopener noreferrer" class="footerBanner">
                <i class="fab fa-linkedin"></i>
            </a>
        </div>
    </div>
</footer>
