<mat-card>
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>{{title}}</mat-card-title>
      <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
      <img mat-card-sm-image [src]="image">
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
  </mat-card-content>
  <mat-card-actions *ngIf="href">
    <a button mat-button [href]="href" rel="nofollow" target="_blank" class="link">
      View
    </a>
  </mat-card-actions>
</mat-card>
