import { ProjectComponent } from './modules/projects/projects.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error404PageComponent } from './shared/pages/error404-page/error404-page.component';
import { HomePageComponent } from './shared/pages/home-page/home-page.component';
import { AppConfig } from './configs/app.config';
import { AboutComponent } from './modules/about/about.component';
import { ContactComponent } from './modules/contact/contact.component';
import { ExpertiseComponent } from './modules/expertise/expertise.component';
import { ExperienceComponent } from './modules/experience/experience.component';

const routes: Routes = [
  { path: '', component: HomePageComponent, pathMatch: 'full' },
  { path: AppConfig.routes.about, component: AboutComponent, pathMatch: 'full' },
  { path: AppConfig.routes.contact, component: ContactComponent, pathMatch: 'full' },
  { path: AppConfig.routes.expertise, component: ExpertiseComponent, pathMatch: 'full' },
  { path: AppConfig.routes.experience, component: ExperienceComponent, pathMatch: 'full' },
  { path: AppConfig.routes.projects, component: ProjectComponent, pathMatch: 'full' },
  { path: AppConfig.routes.error404, component: Error404PageComponent },
  { path: '**', redirectTo: '/' + AppConfig.routes.error404 }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
