import { Component } from '@angular/core';
import { BrowserService } from 'src/app/shared/services/browser.service';

@Component({
  selector: 'app-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.scss'],
})

export class ExpertiseComponent {

  constructor(private browserService: BrowserService) {
    this.browserService.init('Expertise', 'Rohith have worked with a plethora of languages and frameworks', '');
  }

  languages = [
    { name: 'Angular', image: 'assets/images/languages/angular.png' },
    { name: 'React', image: 'assets/images/languages/react.png' },
    { name: '.NET Core', image: 'assets/images/languages/net.png' },
    { name: 'C#', image: 'assets/images/languages/csharp.png' },
    { name: 'Java', image: 'assets/images/languages/java.png' },
    { name: 'JS', image: 'assets/images/languages/js.png' },
    { name: 'TS', image: 'assets/images/languages/ts.png' },
    { name: 'NestJS', image: 'assets/images/languages/nest.png' },
    { name: 'NodeJS', image: 'assets/images/languages/node.png' },
    { name: 'Flutter', image: 'assets/images/languages/flutter.png' },
    { name: 'Android', image: 'assets/images/languages/android.png' },
    { name: 'Ruby on Rails', image: 'assets/images/languages/ror.png' },
    { name: 'C++', image: 'assets/images/languages/cplus.png' },
    { name: 'C', image: 'assets/images/languages/c.png' },
    { name: 'And much more', image: 'assets/images/languages/more.png' },
  ]
}
