import { Component } from '@angular/core';
import { BrowserService } from 'src/app/shared/services/browser.service';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})

export class HomePageComponent {
  constructor(private browserService: BrowserService) {
    this.browserService.init('Rohith Poyyeri', 'Rohith Poyyeri is an application developer from Melbourne, welcome to my portfolio', '');
  }
}
