import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

  isOnline: boolean;

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router) {
    this.isOnline = navigator.onLine;
  }

  ngOnInit() {
    this.title.setTitle('Rohith Poyyeri');

    this.onEvents();
  }

  onEvents() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        switch (event.urlAfterRedirects) {
        case '/':
          this.title.setTitle('Rohith Poyyeri');
          this.meta.updateTag({
            name: 'description',
            content: "Rohith poyyeri's Portfolio"
          });
          break;
        default:
          this.title.setTitle('Rohith Poyyeri');
          this.meta.updateTag({
            name: 'description',
            content: "Rohith poyyeri's Portfolio"
          });
          break;
        }
      }
    });
  }
}
